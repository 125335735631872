// Sassファイルの読み込み

import "../scss/style.scss";


// フェードアニメーション
const scrollAnimationElm = document.querySelectorAll<HTMLElement>('.js-fade');
const scrollAnimationFunc = () => {
  for(let i = 0; i < scrollAnimationElm.length; i += 1) {
    const triggerMargin = 150;
    if (window.innerHeight > scrollAnimationElm[i].getBoundingClientRect().top + triggerMargin) {
      scrollAnimationElm[i].classList.add('is-show');
    }
  }
}

// SPメニュー
window.addEventListener('load', scrollAnimationFunc);
window.addEventListener('scroll', scrollAnimationFunc);

const htmlElm =  document.querySelector<HTMLElement>('.js-noScroll');
const gMenuButtonElm =  document.querySelector<HTMLElement>('.js-gMenuButton');
const menuElm =  document.querySelector<HTMLElement>('.js-gMenu');
const headerElm =  document.querySelector<HTMLElement>('.js-header');
const classToggle = () => {
  gMenuButtonElm?.classList.toggle('is-show');
  menuElm?.classList.toggle('is-show');
  headerElm?.classList.toggle('is-show');
  htmlElm?.classList.toggle('is-noScroll');
}
if(gMenuButtonElm !== null) {
  gMenuButtonElm.addEventListener('click', classToggle);
}

// ヘッダーアニメーション
const header = document.getElementById('header')
const ankerMenu = document.getElementById('ankerMenu')
const height = 80
let offset = 0
let ankerMenuOffset = 0
let clone :  HTMLElement;
let copyHTML : Node;
if (ankerMenu) {
  clone = ankerMenu.cloneNode(true) as HTMLElement;
  if (ankerMenu.parentNode) {
    clone.classList.remove('js-fade');
    clone.classList.add('js-copyAnkerMenu');
    clone.classList.add('is-copy');
    clone.classList.add('is-hide');
    // eslint-disable-next-line
    copyHTML = ankerMenu.parentNode.insertBefore(clone, ankerMenu.nextElementSibling) as HTMLElement;
  }
  ankerMenuOffset = Number(ankerMenu.getBoundingClientRect().top + window.pageYOffset) ? Number(ankerMenu.getBoundingClientRect().top + window.pageYOffset) : 100
}
let lastPosition = 0
let ticking = false

const onScroll = () => {
  if(header) {
    if (lastPosition > offset) {
      header.classList.add('has-shadow');
    } else {
      header.classList.remove('has-shadow');
    }
  }
  if(ankerMenu) {
    if (lastPosition > (ankerMenuOffset - offset)) {
      ankerMenu.classList.add('is-displayNone');
      clone.classList.add('is-fixed');
    } else {
      ankerMenu.classList.remove('is-displayNone');
      clone.classList.remove('is-fixed');
    }
  }
  offset = lastPosition;
}
window.addEventListener('scroll', () => {
lastPosition = window.scrollY;
if (!ticking) {
    window.requestAnimationFrame(() => {
      offset = Number(document.querySelector<HTMLElement>('.js-addShadowPosi')?.clientHeight) ? Number(document.querySelector<HTMLElement>('.js-addShadowPosi')?.clientHeight) : 100
      onScroll()
      ticking = false;
    })
  ticking = true;
}
});

window.addEventListener('DOMContentLoaded', () => {
  const anchorLinks = document.querySelectorAll('a[href^="#"]');
  const anchorLinksArr = Array.prototype.slice.call(anchorLinks);

  anchorLinksArr.forEach(link => {
    link.addEventListener('click', (e: any) => {
      e.preventDefault();
      const targetId = link.hash;
      const targetElement = document.querySelector(targetId);
      const targetOffsetTop = window.pageYOffset + targetElement.getBoundingClientRect().top;
      window.scrollTo({
        top: targetOffsetTop,
        behavior: "smooth"
      });
    });
  });
  const urlHash = location.hash; //URLのハッシュタグを取得
  if (urlHash) { //ハッシュタグが有る場合
    const hashTargetElement = document.querySelector(urlHash);
    const hashTargetOffsetTop = window.pageYOffset + hashTargetElement!.getBoundingClientRect().top;
    window.scrollTo({
      top: hashTargetOffsetTop,
      behavior: "smooth"
    });


  }
});